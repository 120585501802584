<template>
  <v-card flat class="mt-1">
    <div class="pa-3">
      <v-card-text>
        <v-card-title class="px-0 pt-0">
          <v-text-field
            class="pt-0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="payments"
          :search="search"
          :header-props="{ sortByText: 'Ordenar por:' }"
          sort-by="validity"
          hide-default-footer
        >
          <template #[`item.validity`]="{ item }">
            {{ item.validity | date }}
          </template>
          <template #[`item.value`]="{ item }">
            {{ item.value | money }}
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip small :color="item.s.color" outlined label class="font-weight-medium">
              {{ item.s.text }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              v-if="item.status != 'Canceled'"
              icon
              :disabled="!!loading"
              :loading="loading == item.id"
              @click="downloadItem(item)"
            >
              <v-icon>mdi-tray-arrow-down</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </div>
    <boleto />
  </v-card>
</template>

<script>
import moment from "moment";
import rentalService from "@/services/rental";
import Boleto from "@/components/modals/Boleto.vue";

export default {
  components: { Boleto },
  data: () => ({
    search: "",
    headers: [
      { text: "Data Vencimento", value: "validity", sortable: true },
      { text: "Valor", value: "value", sortable: true },
      { text: "Descrição", value: "description", sortable: false },
      { text: "Situação", value: "status", sortable: true, align: "center" },
      { text: "Boleto", value: "actions", sortable: false, align: "end" }
    ],
    loading: null
  }),
  methods: {
    async downloadItem(item) {
      try {
        this.loading = item.id;
        let response = await rentalService.getBoleto(this.rental.id, item.id);
        console.log(response);
        this.$root.$emit("show-boleto", response);
      } catch (e) {
        this.$toast.fire({
          title: e.message || "Erro ao gerar boleto",
          icon: "error"
        });
      } finally {
        this.loading = null;
      }
    },
    paymentStatus(payment) {
      if (payment.status == "Paid") return { text: "Pago", color: "success" };
      if (payment.status == "Canceled") return { text: "Cancelado", color: "secondary" };
      if (payment.status == "Pending" && moment().isAfter(payment.validity)) return { text: "Vencido", color: "error" };
      return { text: "Pendente", color: "warning" };
    }
  },
  computed: {
    payments() {
      let payments = [];
      if (!this.rental) return payments;

      this.rental.Contracts.forEach(c => {
        payments.push(...c.Payment);
      });

      payments = payments.map(p => {
        p.s = this.paymentStatus(p);
        return p;
      });

      return payments;
    }
  },
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    money(value) {
      value = parseFloat(value);
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    }
  },
  props: {
    rental: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
